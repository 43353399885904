import axios from 'axios';
import { mapToDataOnly } from '../base/HttpUtility';

let customerAccessToken = localStorage.getItem('access-token-customer');
let baseUrl = 'AgentPackage'

export default class AgentPakageService {
  get(): Promise<AgentPakageDto[]> {
    return mapToDataOnly<AgentPakageDto[]>(
      axios.get(`/${baseUrl}/Get`
        // {
        //   headers: {
        //     'authorization': `Bearer ${customerAccessToken}`,
        //   }
        // }
      )
    );
  }

  getById(id: string): Promise<AgentPakageDto> {
    return mapToDataOnly<AgentPakageDto>(
      axios.get(`/${baseUrl}/Get/${id}`)
    );
  }
}

export interface AgentPakageDto {
  id?: string;
  title: string;
  enTitle: string;
  description: string;
  enDescription: string;
  isActive: boolean;
  isAvailable: boolean;
  price: number;
  dollarPrice: number;
  discount: number;
  dollarDiscount: number;
  imageId: string;
  imageSrc?: string;
  enImageAlt?: string;
  imageAlt?: string;
  durationDay: number;
  createDate?: string;
  slug?: string;
  enSlug?: string;
  seoTitle: string;
  seoEnTitle: string;
  seoDescription: string;
  seoEnDescription: string;
  seoKeywords: string;
  seoEnKeywords: string;
  itemsInformation: { text: string, image: string, enText: string }[]
}