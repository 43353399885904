import { Helmet } from "react-helmet-async";
import SalesHistory from "../components/history/SalesHistory";

export default function SalesHistoryRoute() {
    return (
        <>
            <Helmet>
                <title>اپل98 - تاریخچه ی فروش </title>
            </Helmet>
            <SalesHistory />
        </>
    )
}