import React, { ChangeEvent } from "react";
import SectionView from "src/components/shared/SectionView/SectionView";
import SalesAgentCheckoutService from "src/services/SalesAgentCheckoutService";
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, TablePagination, TextField } from "@mui/material";
import SalesHistoryList from "./List/SalesHistoryList";
import { SelectionView } from "src/services/models/models";
import AgentOrderPackageService, { AgentOrderHistoryDTO } from "src/services/sales-panel/AgentOrderPackageService";

export default class SalesHistory extends React.Component<{ salesAgentId?: string },
    { loading: boolean, data: AgentOrderHistoryDTO[], totalRows: number, pageSize: number, pageSkip: number, packageId?: string, packagesTitleValues: SelectionView[], usernameQuery?: string }> {

    salesAgentCheckoutService: SalesAgentCheckoutService;

    agentOrderPackageService: AgentOrderPackageService;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            pageSize: 5,
            pageSkip: 0,
            totalRows: 0,
            packagesTitleValues: []
        };
        this.salesAgentCheckoutService = new SalesAgentCheckoutService();

        this.agentOrderPackageService = new AgentOrderPackageService();
    }

    componentDidMount(): void {
        this.fetchData(0, 5);
    }

    fetchData = (PageSkip?: number, PageSize?: number) => {
        this.setState({ loading: true });
        this.agentOrderPackageService.getPagedHistory({ PageSize, PageSkip: PageSkip + 1, packageId: this.state.packageId, email: this.state.usernameQuery })
            .then(res => { this.setState({ data: res.rows, totalRows: res.totalRows }) })
            .finally(() => this.setState({ loading: false }));
    };

    body = () => {
        let { loading, data, pageSize, pageSkip, totalRows, packageId, packagesTitleValues, usernameQuery } = this.state

        return (
            <SectionView title={'تاریخچه ی فروش'}>
                <div className="flex full-width space-between align-center">
                    <Grid container>
                        <Grid item lg={3} md={3} xs={12} sm={3} mr={1} mb={1}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>پکیج ها</InputLabel>
                                <Select
                                    fullWidth
                                    value={packageId}
                                    label="پکیج ها"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ packageId: e.target.value }, () => {
                                            this.fetchData(pageSkip, pageSize)
                                        })
                                    }}
                                >
                                    <MenuItem key={1} value={"-1"}>
                                        <span>همه</span>
                                    </MenuItem>
                                    {packagesTitleValues && packagesTitleValues.map((packagesTitleValue) => (
                                        <MenuItem key={packagesTitleValue.value} value={packagesTitleValue.value}>
                                            <span>{packagesTitleValue.title}</span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={12} sm={3} mr={1} mb={1}>
                            {/* <TextField
                                value={usernameQuery}
                                onChange={e => this.setState({ usernameQuery: e.target.value }, () => {
                                    this.fetchData(pageSkip, pageSize)
                                })}
                                label="جستجو بر اساس نام کاربری"
                                fullWidth
                            /> */}
                        </Grid>
                    </Grid>
                </div>
                <SalesHistoryList loading={loading} rows={data} hasAction={!this.props.salesAgentId} />
                <Box sx={{ direction: 'ltr' }}>
                    <TablePagination
                        component="div"
                        count={totalRows}
                        onPageChange={(event: any, newPageSkip: number) => {
                            this.setState({ pageSkip: newPageSkip })
                            this.fetchData(newPageSkip, pageSize);
                        }}
                        onRowsPerPageChange={(e) => {
                            this.setState({ pageSize: Number(e.target.value) });
                            this.fetchData(pageSkip, parseInt(e.target.value));
                        }}
                        page={pageSkip}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[5, 10, 25, 100]}
                    />
                </Box>
            </SectionView>
        )
    }

    render(): React.ReactNode {

        return (
            <>
                {

                    !this.props.salesAgentId ?
                        <Container sx={{ pt: !this.props.salesAgentId ? '100px' : '0px' }}>
                            {this.body()}
                        </Container> : this.body()
                }
            </>
        );
    }
}