import { useContext, useEffect, useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  lighten,
  Popover,
  Typography,
  Badge
} from '@mui/material';

import { styled } from '@mui/material/styles';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AvatarIcon from '../../../../assets/icons/user-profile.webp'
import NotificationIcon from '../../../../assets/icons/notification.png'
import UserService, { ProfileDataDto } from 'src/services/UserService';
import SalesAgentService from 'src/services/SalesAgentService';
import { DateFormatSimple } from 'src/components/utilities/DateFormat';
import AuthenticationContext from 'src/contexts/AuthenticationContext';
import { ADMIN, MASTER_SALES_AGENT, SALES_AGENT } from 'src/constants/ConstantsPermissions';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

function HeaderUserbox() {
  const { roles, user, agent } = useContext(AuthenticationContext)

  var userService = new UserService();

  var salesAgentService = new SalesAgentService();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const signOut = () => {
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
  }

  return (
    <>
      <div className='flex align-center'>
        <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
          <Typography sx={{ pl: 1, pt: 1 }}>
            <span className='font-bold' style={{ color: '#a2a1a8' }}>{user.firstName ? user.firstName + " " + user.lastName : user.email}</span>
          </Typography>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <Avatar sx={{ bgcolor: 'charcoal.light', width: 33, height: 33 }} src={AvatarIcon} />
          </StyledBadge>
        </UserBoxButton>
        {
          roles.includes(ADMIN) &&
          <NavLink to="/announcements">
            <UserBoxButton color="secondary" sx={{ backgroundColor: 'rgba(110, 117, 159, 0.1)', mr: 2 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <Avatar sx={{ bgcolor: 'charcoal.light', fontSize: '5px', width: 30, height: 30 }} src={NotificationIcon} />
              </StyledBadge>
            </UserBoxButton>
          </NavLink>
        }
      </div>
      {
        (roles.includes(SALES_AGENT) || roles.includes(MASTER_SALES_AGENT)) &&
        <Typography>
          <span style={{ color: '#a2a1a8' }} className='font-bold pl-md'>تاریخ عضویت</span>
          <span style={{ color: '#a2a1a8', display: 'inline-block' }} className='font-heavy'>{DateFormatSimple(agent?.joinAt)}</span>
        </Typography>
      }
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar sx={{ bgcolor: 'charcoal.light', width: 33, height: 33, ml: 1 }} src={AvatarIcon} />
          <UserBoxText sx={{ direction: 'ltr', display: 'flex', flexDirection: 'column', alignItems: 'end', float: 'right' }}>
            {
              user.firstName &&
              <UserBoxLabel variant="body1">{user.firstName + " " + user.lastName}</UserBoxLabel>
            }
            <UserBoxDescription variant="body2">
              {user.email}
            </UserBoxDescription>
            <UserBoxDescription variant="body2">
              {user.phoneNumber}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        {/* <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/management/profile/details" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem button to="/dashboards/messenger" component={NavLink}>
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary="Messenger" />
          </ListItem>
          <ListItem
            button
            to="/management/profile/settings"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Account Settings" />
          </ListItem>
        </List> */}
        {/* <Divider /> */}
        <Box sx={{ m: 1, widows: 190 }}>
          <Button color="error" fullWidth onClick={() => signOut()}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            خروج از حساب کاربری
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
