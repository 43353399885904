import { FC, ReactNode, useContext } from 'react';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import AuthenticationService from 'src/services/AuthenticationService';
import React from 'react';
import authenticationContext from "../../contexts/AuthenticationContext";
import { MASTER_SALES_AGENT, SALES_AGENT } from 'src/constants/ConstantsPermissions';
import SalesAgentService from 'src/services/SalesAgentService';

interface SidebarLayoutProps {
  children?: ReactNode;
}

export default class SidebarLayout extends React.Component<SidebarLayoutProps, { loading: boolean }> {
  authenticationService: AuthenticationService;

  salesAgentService: SalesAgentService;

  static contextType = authenticationContext;

  context!: React.ContextType<typeof authenticationContext>;

  constructor(props: any) {
    super(props);
    this.authenticationService = new AuthenticationService();

    this.salesAgentService = new SalesAgentService();

    this.state = { loading: true }
  }

  componentDidMount(): void {
    this.setState({ loading: true })

    this.authenticationService.getUserData().then((response) => {
      this.context.privileges = response.privilages
      this.context.roles = response.roles
      this.context.user = { email: response.email, firstName: response.firstName, id: response.id, lastName: response.lastName, phoneNumber: response.phoneNumber }
    }).finally(() => {
      if (this.context.roles.includes(SALES_AGENT) || this.context.roles.includes(MASTER_SALES_AGENT))
        this.salesAgentService.getInformation().then((agentResponse) => {
          this.context.agent = { joinAt: agentResponse.joinedAt }
        }).finally(() => this.setState({ loading: false }))
      else
        this.setState({ loading: false })
    })
  }

  render(): React.ReactNode {

    return (<SidebarLayoutB />);
  }
}


const SidebarLayoutB: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { privileges } = useContext(authenticationContext);

  return (<>
    <Box
      sx={{
        flex: 1,
        height: '100%',
        '.MuiPageTitle-wrapper': {
          background:
            theme.palette.mode === 'dark'
              ? theme.colors.alpha.trueWhite[5]
              : theme.colors.alpha.white[50],
          marginBottom: `${theme.spacing(4)}`,
          boxShadow:
            theme.palette.mode === 'dark'
              ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 4px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}, 0px 5px 12px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.05
              )}`
        }
      }}
    >
      <Header />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          display: 'block',
          flex: 1,
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            mr: `${theme.sidebar.width}`
          }
        }}
      >
        <Box display="block">
          <Outlet />
        </Box>
      </Box>
      <Sidebar />
    </Box>
  </>)
}