import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 15,
    padding: '10px',
    border: 'none',
    lineHeight: 1.3,
    borderRadius: 10,
    fontWeight: 'initial',
    fontFamily: [
        'yekan-medium'
    ].join(','),
});
