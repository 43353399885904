import axios from 'axios';
import { mapToDataOnly } from '../base/HttpUtility';
import { PaginationDto, SelectionView } from '../models/models';
import { PaginationView } from '../base/Pagination';

let baseUrl = '/BlogCategory';

export default class BlogCategoryService {
    get(params?: PaginationDto): Promise<PaginationView<BlogCategoryRowDto>> {
        return mapToDataOnly<PaginationView<BlogCategoryRowDto>>(
            axios.get(`${baseUrl}/Get`, { params: params })
        );
    }

    getTitleValue(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(
            axios.get(`${baseUrl}/TitleValue`)
        );
    }

    getById(id: string): Promise<BlogCategoryRowDto> {
        return mapToDataOnly<BlogCategoryRowDto>(axios.get(`${baseUrl}/GetById/${id}`));
    }

    delete(id: string): Promise<void> {
        return mapToDataOnly<void>(axios.delete(`${baseUrl}/Delete/${id}`));
    }

    add(dto: BlogCategoryDto): Promise<void> {
        return mapToDataOnly<void>(axios.post(`${baseUrl}/Insert`, dto));
    }

    edit(id: string, dto: BlogCategoryDto): Promise<void> {
        return mapToDataOnly<void>(axios.put(`${baseUrl}/Update/${id}`, dto));
    }
}
export interface BlogCategoryRowDto {
    id: string;
    title: string;
    enTitle: string;
    orderId: number;
    isActive: boolean;
    parentId: string | null;
    parentTitle: string;
    createAt: string;
    enSlug: string;
    slug: string;
    seoTitle: string;
    enSeoTitle: string;
    seoDescription: string;
    enSeoDescription: string;
}

export interface BlogCategoryDto {
    title: string;
    enTitle: string;
    isActive: boolean;
    parentId: string | null;
    orderId: number;
    enSlug: string;
    slug: string;
    seoTitle: string;
    enSeoTitle: string;
    seoDescription: string;
    enSeoDescription: string;
}