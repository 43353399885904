import axios from 'axios';
import { mapToDataOnly } from '../base/HttpUtility';

export default class VisitorService {
  getPaged(dto: VisitorParamsDto): Promise<PaginationDataDto> {
    return mapToDataOnly<PaginationDataDto>(
      axios.get('/Visitor/Get', { params: dto })
    );
  }
}

export interface VisitorParamsDto {
  PageSize: number;
  PageSkip: number;
  PeriodDate: number;
  Referrer?: string;
}

export interface PaginationDataDto {
  rows: VisitorDto[];
  totalRows: number;
  totalVisits: number;
}

export interface VisitorDto {
  id: string;
  ip: string;
  url: string;
  createAt: string;
  country: string;
  count: number;
}
