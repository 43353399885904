import { Box, CircularProgress, Fade, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { CustomButton } from "src/components/shared/Button/button";
import AuthenticationService from "src/services/AuthenticationService";
import Logp from '../../../../assets/images/logo/logo-black.png'
import './SignInOtp.scss'
import AgentUserService from "src/services/sales-panel/AgentUserService";
import AuthenticationContext from "src/contexts/AuthenticationContext";
import { string } from "prop-types";

export default class SignInOtp extends React.PureComponent<{ nextState: () => void, onLogged: () => void },
    { phoneNumberErrorMessage?: string, collapse: boolean, phoneNumber?: string, otp: string, loading: boolean, otpSent: boolean }> {
    AuthenticationService: AuthenticationService;

    agentUserService: AgentUserService

    static contextType = AuthenticationContext;

    context!: React.ContextType<typeof AuthenticationContext>;

    constructor(props: any) {
        super(props)
        this.state = { phoneNumberErrorMessage: undefined, collapse: false, otp: "", phoneNumber: undefined, loading: false, otpSent: false }
        this.AuthenticationService = new AuthenticationService();

        this.agentUserService = new AgentUserService();
    }

    componentDidMount() {
        this.setState({ collapse: true })
    }

    sendOtp = () => {
        let { phoneNumber } = this.state;

        if (phoneNumber == '')
            toast.error('شماره همراه خود را وارد کنید')
        else {
            this.setState({ loading: true, });
            this.AuthenticationService.sendOtp(this.state.phoneNumber, false)
                .then((response) => {
                    if (response.success) {
                        this.setState({ otpSent: true });
                        toast.success('کد امنیتی برای شماره همراه وارد شده ارسال شد')
                    }
                    else
                        toast.error(response.errorMessage)
                }).catch(() => {
                    this.setState({ otpSent: false });
                }).finally(() => this.setState({ loading: false }));
        }
    }

    validationPhoneNumber = (phoneNumber: string) => {
        let reg = /^(?:\+98|0)?9\d{9}$/;

        let messageError = undefined;

        if (phoneNumber == this.context.user?.phoneNumber) messageError = 'امکان خرید برای این شماره تماس از طریق پنل نمی باشد'
        else if (!reg.test(phoneNumber)) messageError = 'شماره تماس وارد شده نامعتبر می باشد.'

        this.setState({ phoneNumber: phoneNumber, phoneNumberErrorMessage: messageError })
    }

    signIn = () => {
        let { phoneNumber, phoneNumberErrorMessage } = this.state;

        if (phoneNumberErrorMessage != undefined) return;
        if (phoneNumber == undefined) toast.error('شماره همراه خود را وارد کنید')
        else { }

        this.setState({ loading: true });
        this.agentUserService.signIn({ phoneNumber: this.state.phoneNumber })
            .then((response) => {
                localStorage.setItem('access-token-customer', `${response.jwtToken}`);
                this.props.nextState();
                this.props.onLogged();
            }).finally(() => this.setState({ loading: false }));
    }

    bodyView = () => {
        let { loading, phoneNumberErrorMessage, phoneNumber } = this.state;

        return (<>
            <div className="auth-body">
                <img alt="auth logo" src={Logp} className="logo-auth" />
                <span className="title-auth font-heavy">اپل 98</span>
                <span className="subtitle-auth font-bold">فروش اشتراک اپل وان به مشتری</span>
                <br />
                <Box sx={{ pt: 2, pb: 2 }} color="grey" className="full-width">
                    <div className="full-width">
                        <span className="desc-textInput">شماره موبایل مشتری را وارد کنید</span>
                        <TextField
                            sx={{ pb: 1, mt: 2 }}
                            fullWidth
                            id="phoneNumber"
                            value={this.state.phoneNumber}
                            label="شماره موبایل"
                            onChange={(e) => this.validationPhoneNumber(e.target.value)}
                            onKeyDown={(e) => { if (e.key == 'Enter') this.signIn() }}
                        />
                    </div>
                    <span style={{ color: 'red' }} className='size-3'>{phoneNumberErrorMessage}</span>
                </Box>
                <CustomButton disabled={phoneNumber == undefined || phoneNumberErrorMessage != undefined} color='info' onClick={() => this.signIn()} variant="contained" fullWidth>
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'ورود'}
                </CustomButton>
            </div>
        </>)
    }

    render(): React.ReactNode {
        return <div>
            {this.bodyView()}
        </div>
    }
}