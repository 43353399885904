import { useContext, useEffect, useState } from 'react';

import {
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  DASHBOARD_MENU,
  ACCOUNTS_MENU,
  ARTICLE_MENU,
  BANK_CARDS_MENU,
  FACTORS_MENU,
  FAMILIES_MENU,
  FULL_ACCESS,
  PACKAGES_MENU,
  PAYERS_MENU,
  PAYMENT_GATEWAY,
  SETTING_MENU,
  USERS_MENU,
  REDIREDT_LINK_MENU,
  SALES_AGENT_MENU,
  DISCOUNT_CODE_MENU,
} from 'src/constants/ConstantsPermissions';
import authenticationContext from "../../../../contexts/AuthenticationContext";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};
    height: 90vh;
    overflow-y: auto;
    direction: ltr; 

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;
        direction: ltr;
        margin: 4px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          font-weight: 500;
          font-family: yekan-bold !important;
          color: #fff;
          background-color: transparent;
          width: 100%;
          justify-content: flex-end;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: #8080807a;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { privileges, roles } = useContext(authenticationContext);

  let itemMenu = [
    { url: '/sales-agent/cutomer', privilege: undefined, label: 'فروش به مشتری', roles: ['MASTER_SALES_AGENT', 'SALES_AGENT'] },
    { url: '/sales-agent/history', privilege: undefined, label: 'تاریخچه ی فروش', roles: ['MASTER_SALES_AGENT', 'SALES_AGENT'] },
    { url: '/sales-agent/agents', privilege: undefined, label: 'نمایندگان', roles: ['MASTER_SALES_AGENT'] },
    { url: '/sales-agent/agent-orders', label: 'سفارشات نمایندگان', roles: ['MASTER_SALES_AGENT'] },
    { url: '/sales-agent/orders', label: 'سفارشات', roles: ['MASTER_SALES_AGENT'] },
    { url: '/sales-agent/withdrawal', label: 'تسویه حساب ها', roles: ['MASTER_SALES_AGENT'] },
    { url: '/dashboard', label: 'داشبورد', privilege: DASHBOARD_MENU, roles: ['ADMIN'] },
    { url: '/packages', label: 'پکیج ها', privilege: PACKAGES_MENU, roles: ['ADMIN'] },
    { url: '/accounts', label: 'اکانت ها', privilege: ACCOUNTS_MENU, roles: ['ADMIN'] },
    { url: '/payers', label: 'پرداخت کننده ها', privilege: PAYERS_MENU, roles: ['ADMIN'] },
    { url: '/family', label: 'فمیلی ها', privilege: FAMILIES_MENU, roles: ['ADMIN'] },
    { url: '/links', label: 'لینک ها', privilege: FAMILIES_MENU, roles: ['ADMIN'] },
    { url: '/users', label: 'کاربران', privilege: USERS_MENU, roles: ['ADMIN'] },
    { url: '/members', label: 'اعضا', privilege: USERS_MENU, roles: ['ADMIN'] },
    { url: '/orders', label: 'فاکتورها', privilege: FACTORS_MENU, roles: ['ADMIN'] },
    { url: '/bank-cards', label: 'کارت های بانکی', privilege: BANK_CARDS_MENU, roles: ['ADMIN'] },
    { url: '/payment-gateway', label: 'درگاه پرداخت', privilege: PAYMENT_GATEWAY, roles: ['ADMIN'] },
    { url: '/sales-agent', label: 'مدیران فروش', privilege: SALES_AGENT_MENU, roles: ['ADMIN'] },
    { url: '/discount-code', label: 'کد تخفیف', privilege: DISCOUNT_CODE_MENU, roles: ['ADMIN'] },
    { url: '/redirect-links', label: 'لینک های ارجاعی', privilege: REDIREDT_LINK_MENU, roles: ['ADMIN'] },
    { url: '/article', label: 'محتوا', privilege: ARTICLE_MENU, roles: ['ADMIN'] },
    { url: '/article-category', label: 'دسته بندی مقالات', privilege: ARTICLE_MENU, roles: ['ADMIN'] },
    { url: '/FAQ', label: 'سوالات متداول', privilege: ARTICLE_MENU, roles: ['ADMIN'] },
    // { url: '/announcements', label: 'اعلانات', privilege: ANNOUNCEMENTS_MENU,roles:['ADMIN'] },
    // { url: '/seo-settings', label: 'تنظیمات سئو', privilege: SEO_SETTING_MENU,roles:['ADMIN'] },
    { url: '/settings', label: 'تنظیمات', privilege: SETTING_MENU, roles: ['ADMIN'] },
  ]

  return (
    <>
      <MenuWrapper>
        <SubMenuWrapper>
          <List component="div">
            {itemMenu.map((item, index) => {
              var access = item.roles === undefined;
              if (!access)
                for (var role of item.roles) {
                  if (access)
                    break;
                  access = roles.includes(role)
                }
              if (access || (item.privilege != undefined && (privileges.includes(item.privilege) || privileges.includes(FULL_ACCESS))))
                return (
                  <ListItem component="div" key={index}>
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={item.url}
                    >
                      {item.label}
                    </Button>
                  </ListItem>
                )
            })}
          </List>
        </SubMenuWrapper>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
