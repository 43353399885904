import { Card, CardContent, Typography } from '@mui/material'

export default function SectionView(props: { children?: any, title: string, btns?: any }) {
    return <>
        <Typography sx={{ color: 'blue_gray.dark' }}>
            <div className='flex align-center space-between'>
                <span className='size-8 font-heavy'>{props.title}</span>
                <div className="pr-sm">
                    {props.btns}
                </div>
            </div>
        </Typography>
        <Card sx={{ borderRadius: '12px', boxShadow: 'unset', border: '1px solid #f0f2f4', marginTop: '16px' }}>
            <CardContent>
                {props.children}
            </CardContent>
        </Card>
    </>
}