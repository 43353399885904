import React from 'react';
import { Box, Container, TablePagination } from '@mui/material';
import SectionView from '../shared/SectionView/SectionView';
import { CustomButton } from '../shared/Button/button';
import BlogCategoryService, { BlogCategoryRowDto } from 'src/services/content/BlogCategoryService';
import ArticleCategoryList from './List/ArticleCategoryList';
import CreateArticleCategory from './Create/CreateArticleCategory';

export default class ArticleCategory extends React.PureComponent<
  {},
  {
    loading: boolean;
    data: BlogCategoryRowDto[];
    rowSelected?: string;
    isOpenAdd: boolean;
    isOpenDetails: boolean;
    totalRows: number;
    pageSize: number;
    pageSkip: number;
  }
> {
  articleCategoryService: BlogCategoryService;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      isOpenAdd: false,
      isOpenDetails: false,
      pageSize: 5,
      pageSkip: 0,
      totalRows: 0
    };
    this.articleCategoryService = new BlogCategoryService();
  }

  componentDidMount(): void {
    this.fetchData(0, 5);
  }

  fetchData = (PageSkip: number, PageSize: number) => {
    this.setState({ loading: true });
    this.articleCategoryService
      .get({
        PageSize,
        PageSkip: PageSkip + 1,
      })
      .then((res) => {
        this.setState({ data: res.rows, totalRows: res.totalRows });
      }).finally(() => this.setState({ loading: false }));
  };

  delete = (id: any) => {
    this.articleCategoryService.delete(id).then(() => this.fetchData(0, 5));
  };

  renderButtons = () => {
    return (
      <>
        <CustomButton
          color="primary"
          onClick={() => this.setState({ isOpenAdd: true })}
          variant="contained"
        >
          + افزودن
        </CustomButton>
      </>
    );
  };

  render(): React.ReactNode {
    let {
      data,
      loading,
      isOpenAdd,
      rowSelected,
      pageSize,
      pageSkip,
      totalRows,
    } = this.state;

    return (
      <>
        <Container sx={{ pt: '100px' }}>
          <SectionView title={"دسته بندی مقالات"} btns={this.renderButtons()}>
            <ArticleCategoryList
              loading={loading}
              reload={() => this.fetchData(pageSkip, pageSize)}
              openEditedModal={(row) =>
                this.setState({ rowSelected: row.id, isOpenAdd: true })
              }
              openDetails={(row) => this.setState({ isOpenDetails: true, rowSelected: row.id })}
              delete={(id) => this.delete(id)}
              data={data}
            />
            <Box sx={{ direction: 'ltr' }}>
              <TablePagination
                component="div"
                count={totalRows}
                onPageChange={(event: any, newPageSkip: number) => {
                  this.setState({ pageSkip: newPageSkip });
                  this.fetchData(newPageSkip, pageSize);
                }}
                onRowsPerPageChange={(e) => {
                  this.setState({ pageSize: Number(e.target.value) });
                  this.fetchData(pageSkip, parseInt(e.target.value));
                }}
                page={pageSkip}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[5, 10, 25, 100]}
              />
            </Box>
          </SectionView>
        </Container >
        {isOpenAdd && (
          <CreateArticleCategory
            onSubmit={() => this.fetchData(pageSkip, pageSize)}
            onClose={() =>
              this.setState({ isOpenAdd: false, rowSelected: undefined })
            }
            id={rowSelected}
          />)}
      </>
    );
  }
}
