import React from 'react';

import {
  DialogContent,
  DialogActions,
  Dialog,
  Box,
  Typography,
} from '@mui/material';

import './Modal.scss';

export default class Modal extends React.PureComponent<
  {
    onClose?: () => void;
    children: any;
    actionsContent: any;
    title?: string;
    className?: string;
    subTitle?: string;
    width?: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    let { } = this.state;
    let { onClose, children, actionsContent, subTitle, title, width } = this.props;

    return (
      <Dialog className={`modal ${this.props.className}`} fullWidth maxWidth={width} open={true} onClose={onClose}>
        <DialogContent>
          <Typography sx={{ color: 'blue_gray.dark' }} mb={1}>
            <span className='size-7 font-heavy'>
              {title}
            </span>
          </Typography>
          <Typography sx={{color: 'gray'}}>
            <span className='size-4'>
              {subTitle}
            </span>
          </Typography>
          <Box sx={{mt: 2}}>
            {children}
          </Box>
        </DialogContent>
        <DialogActions dir='ltr' sx={{ justifyContent: 'flex-start', mb: 2, ml: 2 }}>
          {actionsContent}
        </DialogActions>
      </Dialog>
    );
  }
}
