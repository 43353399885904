import React from "react";
export interface IAuthenticationContext {
    privileges: string[];
    roles: string[];
    user: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
    };
    agent?: {
        joinAt: string
    }
}
const AuthenticationContext = React.createContext<IAuthenticationContext>({
    privileges: [],
    roles: [],
    user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
    },
    agent: undefined
});

export default AuthenticationContext;
