import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import AuthenticationService from "src/services/AuthenticationService";
import { CustomButton } from "src/components/shared/Button/button";

export default class CompletionInformation extends React.PureComponent<{ nextState: () => void, logout: () => void }, { name: string, family: string, email: string, loading: boolean }> {

    authenticationService: AuthenticationService;

    constructor(props: any) {
        super(props)
        this.state = { loading: false, email: "", family: "", name: "" }
        this.authenticationService = new AuthenticationService();
    }

    componentDidMount(): void {
        this.authenticationService.getUserInformationByToken()
            .then(response => {
                if (response)
                    this.setState({ email: response.email, family: response.lastName, name: response.firstName })
            })
    }

    submit = () => {
        let { email, family, name } = this.state;

        if (email == '' || family == '' || name == '')
            toast.error('لطفا تمام فیلد هارو پر کنید.')
        else {
            this.setState({ loading: true });
            let token = localStorage.getItem('access-token-customer');

            this.authenticationService.completeInformationCustomer({ email, firstName: name, lastName: family }, token)
                .then(() => {
                    this.props.nextState();
                }).finally(() => this.setState({ loading: false }));
        }
    }

    render(): React.ReactNode {
        let { email, family, name, loading } = this.state;

        return <div>
            <form method="post">
                <Box className="element-center" sx={{ maxWidth: '500px' }}>
                    <div className="font-heavy size-6">تکمیل اطلاعات</div>
                    <div className="font-bold">اطلاعات زیر را از مشتری دریافت و اینجا وارد کنید</div>
                    <Grid container className="mt-lg mb-lg">
                        <Grid item lg={6} md={6} sm={6} xs={12} mb={1} className="full-width">
                            <span className="text">نام مشتری</span>
                            <TextField
                                sx={{ pt: 1, pl: 1 }}
                                fullWidth
                                value={name}
                                id="otp"
                                label=""
                                onChange={(e) => this.setState({ name: e.target.value })}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} mb={2} className="full-width">
                            <span className="text">نام خانوادگی مشتری</span>
                            <TextField
                                sx={{ pt: 1, pr: 1 }}
                                fullWidth
                                value={family}
                                id="otp"
                                label=""
                                onChange={(e) => this.setState({ family: e.target.value })}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mb={2} className="full-width">
                            <span className="text">اپل آیدی (ایمیل اپل آیدی) مشتری</span>
                            <TextField
                                sx={{ pt: 1 }}
                                fullWidth
                                value={email}
                                id="otp"
                                label=""
                                onChange={(e) => this.setState({ email: e.target.value })}
                                onKeyDown={(e) => { if (e.key == 'Enter') this.submit() }}
                            />
                        </Grid>
                    </Grid>
                    <CustomButton color='primary' sx={{mb: 1}} onClick={() => { this.props.logout() }} variant="text">
                        ویرایش شماره
                    </CustomButton>
                    <CustomButton color='info' onClick={() => { this.submit() }} variant="contained" fullWidth>
                        {loading ? <CircularProgress color="inherit" size={20} /> : 'ادامه'}
                    </CustomButton>
                </Box>
            </form>
        </div >
    }
}