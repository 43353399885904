import { Helmet } from "react-helmet-async";
import SalesAgentDashboard from "src/components/dashboard/SalesAgentDashboard";

export default function SalesAgentDashboardRoute() {
    return (
        <>
            <Helmet>
                <title>اپل98 - داشبورد </title>
            </Helmet>
            <SalesAgentDashboard />
        </>
    )
}