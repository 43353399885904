import React from 'react';
import { Container } from '@mui/material';
import Banner from '../../assets/images/banners/tgbanner.jpg'
import authenticationContext from '../../contexts/AuthenticationContext'

export default class SalesAgentDashboard extends React.Component<{}, { loading: boolean }> {
    static contextType = authenticationContext;

    context!: React.ContextType<typeof authenticationContext>;

    constructor(props: any) {
        super(props);

        this.state = { loading: true }
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.setState({ loading: false })
        }, 2000)
    }

    render(): React.ReactNode {
        return (
            <>
                <Container sx={{ pt: '100px', display: 'flex', height: '80vh', alignItems: 'flex-end', justifyContent: 'center' }}>
                    {
                        this.context.roles.length > 0 && <a href={this.context.roles.includes("MASTER_SALES_AGENT") ? "https://t.me/+GRkY9aQm5T43OWMx" : "https://t.me/+2owd1ENpbiVmN2Ex"}>
                            <img src={Banner} style={{ maxWidth: '100%' }} />
                        </a>
                    }
                </Container>
            </>
        );
    }
}


