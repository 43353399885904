import moment from 'jalali-moment';

export default function DateFormat(date: any) {
  return (
    <>
      {!date || date === null
        ? ''
        : <span className='fa-number'>{moment(date).locale('fa').format('HH:mm - YYYY/M/D')}</span>
      }
    </>
  );
}

export function DateFormatSimple(date: any) {
  return (
    <>
      {!date || date == null
        ? ''
        : <span>{moment(date).locale('fa').format('YYYY-MM-DD')}</span>
      }
    </>
  );
}
