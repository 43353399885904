import axios from 'axios';
import { mapToDataOnly } from './base/HttpUtility';

export default class ConfigService {
  get(): Promise<ConfigDto[]> {
    return mapToDataOnly<ConfigDto[]>(axios.get(`/Config/Get`));
  }

  set(dto: { key: string; value: string }[]): Promise<boolean> {
    return mapToDataOnly<boolean>(axios.put('/Config/Update', dto));
  }
}

export interface ConfigDto {
  id: string;
  key: string;
  value: string;
}
