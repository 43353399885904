import { Routing } from 'src/router';
import './assets/styles/app.scss'
import './assets/styles/typography.scss'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ThemeProvider from './theme/ThemeProvider';
import { returnBaseUrl } from './constants/constants';
import AuthenticationContext, { IAuthenticationContext } from './contexts/AuthenticationContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const authenticationContext: IAuthenticationContext = {
    privileges: [],
    roles: [],
    user: { email: '', firstName: '', id: '', lastName: '', phoneNumber: '' },
    agent: undefined
  };

  let token = localStorage.getItem('accessToken');
  let dashboard = window.location.host.includes('agent') ? "/sales-agent/dashboard" : "/dashboard"

  if (token && window.location.pathname == '/login') window.location.href = dashboard
  if (!token && !['/login', '/forgot-password'].includes(window.location.pathname))
    window.location.href = '/login';

  function axiosConfig() {    
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        if (!error.response) {
          toast.error('مشکل اتصال سرور، لطفا اینترنت را بررسی کنید')
        }
        else if (
          error.response.status === 401 &&
          window.location.pathname !== '/login'
        ) {
          localStorage.removeItem('accessToken');
          window.location.href = '/login';
        } else if (error.response.status === 404) {
          window.location.href = '/not-found';
        } else if (error.response.status === 403) {
          window.location.href = '/status/403';
        } else if (error.response.status === 400) {
          toast.error(error.response.data || error.message)
        }
      }
    );

    if (token !== undefined && token !== null && token !== '')
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['language'] = `fa`;

    axios.defaults.baseURL = returnBaseUrl();
  }

  axiosConfig();

  return (
    <div className="App">
      <HelmetProvider>
        <AuthenticationContext.Provider value={authenticationContext}>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SidebarProvider>
                <ToastContainer
                  position="top-center"
                  bodyClassName={"font-medium text-right"}
                />
                <CssBaseline />
                <Routing />
              </SidebarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </AuthenticationContext.Provider>
      </HelmetProvider>
    </div >
  );
}
export default App;