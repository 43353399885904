import React, { ChangeEvent, RefObject } from 'react';
import { Box, Typography, CircularProgress, TextField, Grid, FormControl, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import Modal from 'src/components/shared/modal/Modal';
import { CustomButton } from 'src/components/shared/Button/button';
import Uploader from 'src/components/shared/Uploader/Uploader';
import BlogCategoryService, { BlogCategoryRowDto } from 'src/services/content/BlogCategoryService';
import { SelectionView } from 'src/services/models/models';

export default class CreateArticleCategory extends React.PureComponent<
  { onClose: () => void; onSubmit: () => void; id?: string },
  {
    blogCategory?: BlogCategoryRowDto;
    pendingRequest: boolean;
    loading: boolean;
    titleValues: SelectionView[]
  }
> {
  uploader: RefObject<Uploader>;

  blogCategoryService: BlogCategoryService;

  constructor(props: any) {
    super(props);
    this.state = {
      pendingRequest: false,
      loading: false,
      titleValues: []
    };
    this.blogCategoryService = new BlogCategoryService();
  }

  fetchCategories = () => {
    this.blogCategoryService.getTitleValue().then(response => {
      this.setState({ titleValues: response })
    })
  }

  componentDidMount(): void {
    let { id } = this.props;

    this.fetchCategories()

    if (id) {
      this.setState({ loading: true });
      this.blogCategoryService.getById(id).then(response => {
        this.setState({ blogCategory: response })
      }).finally(() => this.setState({ loading: false }))
    }
  }

  submit = () => {
    let { id } = this.props;
    let { blogCategory } = this.state;

    this.setState({ pendingRequest: true });
    if (id) this.edit({ ...blogCategory });
    else this.add({ ...blogCategory });
  };

  add = (body) => {
    this.blogCategoryService.add(body).finally(() => {
      this.setState({ pendingRequest: false });
      this.props.onSubmit();
      this.props.onClose();
    });
  };

  edit = (body) => {
    let { id, onSubmit, onClose } = this.props;

    this.blogCategoryService.edit(id, body).finally(() => {
      this.setState({ pendingRequest: false });
      onSubmit();
      onClose();
    });
  };

  render(): React.ReactNode {
    let { id } = this.props;
    let { blogCategory, loading } = this.state;

    return (
      <Modal
        width={'md'}
        title={id ? `ویرایش دسته بندی` : `دسته بندی جدید`}
        subTitle={
          `اطلاعات را وارد کنید و سپس روی دکمه افزودن کلیک کنید ، دسته بندی شما در لیست قرار میگیرد.`
        }
        actionsContent={
          <>
            {this.state.pendingRequest ? (
              <CircularProgress color="primary" size={20} sx={{ m: 3 }} />
            ) : (
              <CustomButton
                onClick={this.submit}
                variant="contained"
              >
                {id ? 'ویرایش' : 'تایید'}
              </CustomButton>
            )}
            <CustomButton
              variant="outlined"
              color="error"
              onClick={this.props.onClose}
            >
              لغو
            </CustomButton>
          </>
        }
      >
        {loading
          ? <Grid m={5} display='flex' justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="inherit" size={42} />
          </Grid>
          : <Box>
            <Grid container mt={4}>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.title}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, title: e.target.value }
                    })
                  }
                  margin="dense"
                  id="title"
                  label="عنوان"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.enTitle}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, enTitle: e.target.value }
                    })
                  }
                  margin="dense"
                  id="enTitle"
                  label="عنوان انگلیسی"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.slug}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, slug: e.target.value }
                    })
                  }
                  margin="dense"
                  id="slug"
                  label="Slug"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.enSlug}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, enSlug: e.target.value }
                    })
                  }
                  margin="dense"
                  id="en-slug"
                  label="En Slug"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  value={blogCategory?.orderId}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, orderId: Number(e.target.value) }
                    })
                  }
                  margin="dense"
                  id="orderId"
                  label="ترتیب"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} mb={1} p={1}>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <Typography mb={1}>دسته بندی:</Typography>
                  <Select
                    value={blogCategory?.parentId}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        blogCategory: {
                          ...blogCategory,
                          parentId: e.target.value
                        }
                      })
                    }}
                  >
                    <MenuItem value={undefined}>
                      <span>بدون انتخاب</span>
                    </MenuItem>
                    {this.state.titleValues.map((titleValue) => (
                      <MenuItem key={titleValue.value} value={titleValue.value}>
                        <span>{titleValue.title}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container mt={4}>

              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.seoTitle}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, seoTitle: e.target.value }
                    })
                  }
                  margin="dense"
                  id="seoTitle"
                  label="عنوان سئو"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.enSeoTitle}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, enSeoTitle: e.target.value }
                    })
                  }
                  margin="dense"
                  id="enSeoTitle"
                  label="عنوان سئو انگلیسی"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.seoDescription}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, seoDescription: e.target.value }
                    })
                  }
                  margin="dense"
                  id="seoDescription"
                  label="توضیحات سئو"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={1} p={1}>
                <TextField
                  autoFocus
                  value={blogCategory?.enSeoDescription}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: { ...blogCategory, enSeoDescription: e.target.value }
                    })
                  }
                  margin="dense"
                  id="enSeoDescription"
                  label="توضیحات سئو انگلیسی"
                  type="text"
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={3} md={3} sm={6} xs={12} mb={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <FormControlLabel
                  value={blogCategory?.isActive}
                  onChange={(e) =>
                    this.setState({
                      blogCategory: {
                        ...blogCategory,
                        isActive: !blogCategory?.isActive
                      }
                    })
                  }
                  control={
                    <Switch color="success" defaultChecked={blogCategory?.isActive} />
                  }
                  label=":فعال"
                  sx={{ direction: 'ltr' }}
                />
              </Grid>
            </Grid>
          </Box>
        }
      </Modal>
    );
  }
}