import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { Box, Input, Skeleton } from '@mui/material'
import { useState } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Pagination from '../pagination/PaginationComponent'
import { IStripedGridProps } from './GridData.models'

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  fontFamily: ['yekan-medium'].join(','),
  border: 'unset',
  width: '100%',
  direction: 'rtl',
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#f5f7f9',
    borderRadius: '12px',
    border: 'unset',
    color: '#1d1d1d',
    fontSize: '14px',
  },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    paddingTop: '12px',
  },
  '& .MuiDataGrid-row': {
    borderRadius: '12px',
  },
  '.MuiDataGrid-cell': {
    borderBottom: 'unset',
    fontSize: '14px',
    fontFamily: 'yekan-number-regular',
    pointerEvents: 'none',
    color: '#1d1d1d',
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#f5f7f98c",
  },
}))

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>موردی جهت نمایش وجود ندارد</Box>
    </StyledGridOverlay>
  );
}

export default function GridData(props: IStripedGridProps) {
  return (
    <>
      {props.children}
      {
        props.loading ?
          <Skeleton width={'100%'} height={400} /> :
          <div style={{ height: 400 }}>
            <StripedDataGrid
              rows={props.rows}
              columns={props.columns}
              disableVirtualization
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              rowSelection={props.checkboxSelection}
              hideFooterSelectedRowCount
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              hideFooterPagination
              hideFooter
              rowHeight={42}
              getRowClassName={(params: any) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              checkboxSelection={props.checkboxSelection}
              disableRowSelectionOnClick
              onRowSelectionModelChange={props.onRowSelectionModelChange}
            />
          </div>
      }
      {(props.hasPagination && props.totalRows) && <div className='pt-sm'><Pagination totalRows={props.totalRows} onChangePage={(page) => console.log('page', page)} /> </div>}
    </>
  )
}

function Header(props: { children?: any }) {
  return (
    <div className="py-md flex justify-between">
      {props.children}
    </div>
  )
}

function HeaderItem(props: { text: string, value: string }) {
  return (
    <div className="size-5 pb-sm">
      <span className='font-regular charcoal-main text'>{props.text}</span>:
      <span className='font-bold pl-sm'>{props.value}</span>
    </div>
  )
}

function Search(props: { placeholder?: string }) {
  const [value, setValue] = useState("")

  return (
    <div className="pb-sm">
      <Input
        value={value}
        size="small"
        placeholder={props.placeholder}
        endAdornment={
          <SearchRoundedIcon sx={{ color: 'charcoal.light' }} />
        }
        sx={{ fontSize: 16 }}
        onChange={(e) => {
          setValue(e.target.value)
        }}
      />
    </div>
  )
}


GridData.Header = Header
GridData.Search = Search
GridData.HeaderItem = HeaderItem