import { Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { PackageDto } from "src/services/PackageService";
import AgentPakageService from "src/services/sales-panel/AgentPakageService";
import APPLEARCADE from '../../../../assets/images/plans-items/APPLEARCADE-plan.png'
import APPLEFITNESS from '../../../../assets/images/plans-items/APPLEFITNESS-plan.png'
import APPLEMUSIC from '../../../../assets/images/plans-items/APPLEMUSIC-plan.png'
import APPLENEWS from '../../../../assets/images/plans-items/APPLENEWS-plan.png'
import APPLETV from '../../../../assets/images/plans-items/APPLETV-plan.png'
import ICLOUD from '../../../../assets/images/plans-items/ICLOUD-plan.png'
import Warning from '../../../../assets/icons/Warning-Circle-White-md.png'
import './PackageDetails.scss'
import FormatMoney from "src/components/shared/FormatMoney/FormatMoney";
import { CustomButton } from "src/components/shared/Button/button";
import AgentOrderPackageService from "src/services/sales-panel/AgentOrderPackageService";

export default class PackageDetails extends React.PureComponent<{ nextState: () => void, prevState: () => void, packageId: string, customerUserId: string }, { packageData?: PackageDto, loading: boolean, pendingRequest: boolean }> {

    agentPakageService: AgentPakageService;

    agentOrderPackageService: AgentOrderPackageService;

    constructor(props: any) {
        super(props)
        this.state = { loading: false, pendingRequest: false }

        this.agentPakageService = new AgentPakageService();

        this.agentOrderPackageService = new AgentOrderPackageService();
    }

    componentDidMount(): void {
        this.setState({ loading: true })
        this.agentPakageService.getById(this.props.packageId).then((response) => {
            this.setState({
                packageData: response
            })
        }).finally(() => this.setState({ loading: false }))
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        this.agentOrderPackageService.insert({ packageId: this.props.packageId, callback: window.location.href, userId: this.props.customerUserId })
            .then((response) => {
                window.location.href = response;
            })
            .finally(() => this.setState({ pendingRequest: true }))
    }

    renderImg = (key: string) => {
        switch (key) {
            case `APPLEARCADE`:
                return APPLEARCADE;
            case `APPLEFITNESS`:
                return APPLEFITNESS;
            case `APPLEMUSIC`:
                return APPLEMUSIC;
            case `APPLENEWS`:
                return APPLENEWS;
            case `APPLETV`:
                return APPLETV;
            case 'ICLOUD':
                return ICLOUD;
        }
    }

    packageView = () => {
        let { packageData } = this.state

        return <div className="full-width">
            <div className="title-view">
                <img alt={packageData.imageAlt} className="square margin-l-md" src={packageData.imageSrc} />
                <div className="header-modal-text">
                    <div className="size-7 font-heavy">{packageData.title}</div>
                    <div className="size-5">
                        <span className="size-6 font-bold fa-number">{packageData.durationDay}</span>
                        <span className="pr-xs size-4">روز</span>
                    </div>
                    <div>
                        <span className="size-6 font-heavy fa-number">{FormatMoney(packageData.price - packageData.discount)}</span>
                        <span className="pr-xs size-4">تومان</span>
                    </div>
                </div>
            </div>
            <div className="plan-desc-subscripion">
                <div className="mb-3">
                    <span> {packageData.title} </span>
                    <span> شامل سرویس های  </span>
                </div>
                <div className="mb-3">
                    {
                        (packageData.itemsInformation && packageData.itemsInformation.length > 0) && packageData.itemsInformation.map((itemInformation, index) => {
                            return <div className="font-sm pb-sm flex align-items-center" key={index}>
                                <img className="plan-items-img margin-end-sm" src={this.renderImg(itemInformation.image)} />
                                <span>{itemInformation.text}</span>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    }

    render(): React.ReactNode {
        let { loading, packageData } = this.state;

        return <Box width={'80%'} className="flex column justify-center align-center element-center">
            <div className="size-6 font-heavy">تایید و پرداخت</div>
            {
                loading ?
                    <Grid m={5} display='flex' justifyContent={'center'} alignItems={'center'}>
                        <CircularProgress color="inherit" size={42} />
                    </Grid> :
                    <>
                        <div className="banner-style mt-md mb-md">
                            <div className="pa-sm flex align-items-center">
                                <img alt="warning" src={Warning} className="pa-sm" />
                                <span className="font-sm">لطفا قبل از خرید اشتراک مطمئن شوید <b>ریجن (کشور) اپل آیدی مشتری روی آمریکا</b> تنظیم شده باشد!</span>
                            </div>
                        </div>
                        {packageData && this.packageView()}
                        <div className="mt-md flex justify-end full-width">
                            <CustomButton color='info' onClick={() => { this.props.prevState() }} variant="outlined">بازگشت</CustomButton>
                            {
                                this.state.pendingRequest ?
                                    <CustomButton color='inherit' sx={{ mr: 1 }} variant="contained">
                                        <CircularProgress color="info" size={20} />
                                    </CustomButton> :
                                    <CustomButton color='info' sx={{ mr: 1 }} onClick={this.submit} variant="contained">تایید و پرداخت</CustomButton>
                            }
                        </div>
                    </>
            }
        </Box>
    }
}