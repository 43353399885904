import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import GridData from 'src/components/shared/grid/striped/GridData';
import SalesAgentCheckoutService from 'src/services/SalesAgentCheckoutService';
import DateFormatEn from 'src/components/utilities/DateFormatEn';
import { Tooltip } from '@mui/material';
import DateFormat from 'src/components/utilities/DateFormat';
import { CustomButton } from 'src/components/shared/Button/button';
import { toast } from 'react-toastify';
import { AgentOrderHistoryDTO } from 'src/services/sales-panel/AgentOrderPackageService';
import { returnBaseUrl } from 'src/constants/constants';

export default class SalesHistoryList extends React.PureComponent<
    { hasAction?: boolean, rows: AgentOrderHistoryDTO[]; loading?: boolean }
    , { isOpenOrdersModal: boolean, rowId?: string }
> {
    salesAgentCheckoutService: SalesAgentCheckoutService;

    constructor(props: any) {
        super(props);
        this.salesAgentCheckoutService = new SalesAgentCheckoutService();
        this.state = { isOpenOrdersModal: false };
    }

    copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            toast.info("Copied.", { style: { width: "200px" } })
            return navigator.clipboard.writeText(text)
        }
    }

    render(): React.ReactNode {
        let { rows } = this.props;

        const columns: GridColDef[] = [
            { field: 'email', width: 200, headerName: 'اپل آیدی مشتری', sortable: false },
            {
                field: 'packageName',
                width: 250,
                headerName: 'پکیج',
                sortable: false,
            },
            {
                field: 'createAt',
                width: 120,
                headerName: 'تاریخ خرید',
                sortable: false,
                renderCell: (params) => (
                    <Tooltip title={DateFormatEn(params?.value)}>
                        <span className="table-cell-trucate">
                            {DateFormat(params?.value)}
                        </span>
                    </Tooltip>
                )
            },
            {
                field: 'inviteLink',
                width: 280,
                headerName: 'لینک عضویت',
                sortable: false,
                renderCell: (params) => (
                    <span>{params.value ? params.value : ''}</span>
                )
            },
            {
                field: 'actions',
                type: 'actions',
                align: 'right',
                width: 150,
                getActions: (params) => [
                    <CustomButton
                        key={params.id}
                        sx={{ fontSize: 12, p: '8px 12px' }}
                        variant="text"
                        color='warning'
                        onClick={() => this.copyTextToClipboard(params.row.inviteLink)}
                    >
                        کپی کردن لینک عضویت
                    </CustomButton>
                ]
            }
        ];

        return (
            <>
                <GridData columns={columns} rows={rows} loading={this.props.loading}></GridData>
            </>
        )
    }
}