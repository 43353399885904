import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';


import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import IdentityLayout from './layouts/IdentityLayout';
import Dashboard from './components/dashboard/Dashboard';
import SalesAgentDashboard from './components/dashboard/SalesAgentDashboard';
import { Route, Routes } from "react-router-dom";
import ArticleCategory from './components/article-category/ArticleCategory';
import SalesCustomersRoute from './sales-panel/routes/SalesCustomersRoute';
import SalesHistoryRoute from './sales-panel/routes/SalesHistoryRoute';
import SalesAgentDashboardRoute from './sales-panel/routes/SalesAgentDashboardRoute';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loader(lazy(() => import('src/routes/identity/SignInRoute')));
const ForgotPassword = Loader(lazy(() => import('src/routes/identity/ForgotPasswordRoute')));
const Packages = Loader(
  lazy(() => import('src/routes/package/ManagePackagesRoute'))
);
const Accounts = Loader(
  lazy(() => import('src/routes/account/ManageAccountRoute'))
);
const BankCards = Loader(
  lazy(() => import('src/routes/bankCards/BankCardsRoute'))
);
const Orders = Loader(
  lazy(() => import('src/routes/order/OrderRoute'))
);
const Users = Loader(
  lazy(() => import('src/routes/users/ManageUsersRoute'))
);
const Members = Loader(
  lazy(() => import('src/routes/member/ManageMemberRoute'))
);
const Payers = Loader(
  lazy(() => import('src/routes/payers/ManagePayersRoute'))
);
const Family = Loader(
  lazy(() => import('src/routes/family/FamilyRoute'))
);
const Announcements = Loader(
  lazy(() => import('src/routes/announcement/AnnouncementRoute'))
);
const Article = Loader(
  lazy(() => import('src/routes/article/ManageArticleRoute'))
);
const FAQ = Loader(
  lazy(() => import('src/routes/faq/FAQRoute'))
);
const Settings = Loader(
  lazy(() => import('src/routes/settings/SettingsRoute'))
);
const SeoSettings = Loader(
  lazy(() => import('src/routes/settings/seo/SeoSettingsRoute'))
);
const PaymentGateway = Loader(
  lazy(() => import('src/routes/paymentGateway/paymentGatewayRoute'))
);
const RedirectLinks = Loader(
  lazy(() => import('src/routes/redirectLinks/RedirectLinksRoute'))
);
const SalesAgent = Loader(
  lazy(() => import('src/routes/sales-agent/SalesAgentRoute'))
);
const DiscountCode = Loader(
  lazy(() => import('src/routes/discount-code/DiscountCodeRoute'))
);
const Links = Loader(
  lazy(() => import('src/routes/link/LinkRoute'))
);
const AgentsRoute = Loader(
  lazy(() => import('src/routes/sales-agent/AgentRoute'))
);
const AgentOrdersRoute = Loader(
  lazy(() => import('src/routes/sales-agent/AgentOrdersRoute'))
);
const SubsetAgentOrdersRoute = Loader(
  lazy(() => import('src/routes/sales-agent/SubsetAgentOrdersRoute'))
);
const AgentCheckoutsRoute = Loader(
  lazy(() => import('src/routes/sales-agent/AgentCheckoutsRoute'))
);
// Status

const Status403 = Loader(
  lazy(() => import('src/content/pages/Status/Status403'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <IdentityLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '403',
            element: <Status403 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*' || 'not-found',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'salesAgent',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <SalesAgentDashboard />
      },
      {
        path: 'agents',
        element: <AgentsRoute />
      },
      {
        path: 'orders',
        element: <AgentOrdersRoute />
      }, {
        path: 'agent-orders',
        element: <SubsetAgentOrdersRoute />
      },
      {
        path: 'withdrawal',
        element: <AgentCheckoutsRoute />
      }
    ]

  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'packages',
        element: <Packages />
      },
      {
        path: 'accounts',
        element: <Accounts />
      },
      {
        path: 'bank-cards',
        element: <BankCards />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'users',
        element: <Users />
      },
      {
        path: 'members',
        element: <Members />
      },
      {
        path: 'payers',
        element: <Payers />
      },
      {
        path: 'family',
        element: <Family />
      },
      {
        path: 'article',
        element: <Article />
      },
      {
        path: 'settings',
        element: <Settings />
      },
      {
        path: 'seo-settings',
        element: <SeoSettings />
      },
      {
        path: 'payment-gateway',
        element: <PaymentGateway />
      },
      {
        path: 'links',
        element: <Links />
      },
      {
        path: 'announcements',
        element: <Announcements />
      },
      {
        path: 'redirect-links',
        element: <RedirectLinks />
      },
      {
        path: 'sales-agent',
        element: <SalesAgent />
      },
      {
        path: 'discount-code',
        element: <DiscountCode />
      },
      {
        path: '',
        element: <Accounts />
      },
    ]
  }
];

export default routes;


export function Routing() {
  return (<Routes>
    <Route element={<SidebarLayout />}>
      <Route path='' element={<Navigate to={window.location.host.includes('agent') ? "sales-agent/dashboard" : "dashboard"} replace />}></Route>
      <Route path='/dashboard' element={<Dashboard />}></Route>
      <Route path="/sales-agent" element={<SalesAgent />}></Route>
      <Route path="/discount-code" element={<DiscountCode />} />
      <Route path="/redirect-links" element={<RedirectLinks />} />
      <Route path="/announcements" element={<Announcements />} />
      <Route path="/links" element={<Links />} />
      <Route path="/payment-gateway" element={<PaymentGateway />} />
      <Route path="/seo-settings" element={<SeoSettings />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/article" element={<Article />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/article-category" element={<ArticleCategory />} />
      <Route path="/family" element={<Family />} />
      <Route path="/payers" element={<Payers />} />
      <Route path="/users" element={<Users />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/bank-cards" element={<BankCards />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/packages" element={<Packages />} />
      <Route path="/members" element={<Members />} />
    </Route>
    <Route element={<SidebarLayout />}>
      <Route path='' element={<Navigate to={window.location.host.includes('agent') ? "sales-agent/dashboard" : "dashboard"} replace />}></Route>
      <Route path='/sales-agent/dashboard' element={<SalesAgentDashboardRoute />}></Route>
      <Route path='/sales-agent/agents' element={<AgentsRoute />}></Route>
      <Route path="/sales-agent/orders" element={<AgentOrdersRoute />}></Route>
      <Route path="/sales-agent/agent-orders" element={<SubsetAgentOrdersRoute />} />
      <Route path="/sales-agent/withdrawal" element={<AgentCheckoutsRoute />} />
      <Route path="/sales-agent/cutomer" element={<SalesCustomersRoute />} />
      <Route path="/sales-agent/history" element={<SalesHistoryRoute />} />
    </Route>
    <Route element={<BaseLayout />}>
      <Route path='' element={<Navigate to={window.location.host.includes('agent') ? "sales-agent/dashboard" : "dashboard"} replace />}></Route>
      <Route path='/status/404' element={<Status404 />}></Route>
      <Route path='/status/403' element={<Status403 />}></Route>
      <Route path="/status/500" element={<Status500 />}></Route>
    </Route>
    <Route element={<IdentityLayout />}>
      <Route path='' element={<Navigate to={window.location.host.includes('agent') ? "sales-agent/dashboard" : "dashboard"} replace />}></Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='/forgot-password' element={<ForgotPassword />}></Route>
    </Route>
  </Routes>
  )
}