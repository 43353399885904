import axios from 'axios';
import { mapToDataOnly } from './base/HttpUtility';
import { PaginationDto } from './models/models';
import { StatusEnum } from 'src/components/order/Enum/StatusEnum';

export default class OrderService {
  getOrders(dto?: OrderParamsDto): Promise<PaginationDataDto<OrderDto>> {
    return mapToDataOnly<PaginationDataDto<OrderDto>>(
      axios.get('/Order/ListOrders', { params: dto })
    );
  }

  getById(orderId: string): Promise<OrderRowDto> {
    return mapToDataOnly<OrderRowDto>(axios.get(`Order/${orderId}`));
  }

  getStatistic(periodDate?: number): Promise<OrderStatisticView> {
    return mapToDataOnly<OrderStatisticView>(
      axios.get(`/Order/Statistics`, { params: { periodDate } })
    );
  }

  getUserSuccessfulOrders(queryString: UserSuccessfulOrdersQueryString): Promise<PaginationDataDto<UserSuccessfulOrderDTO>> {
    return mapToDataOnly<PaginationDataDto<UserSuccessfulOrderDTO>>(
      axios.get(`/Order/Successful`, { params: { ...queryString } })
    );
  }

  changeOrderStatus(orderId: string, status: StatusEnum): Promise<void> {
    return mapToDataOnly<void>(
      axios.put(`/Order/Status`, { id: orderId, status })
    );
  }
}

export interface UserSuccessfulOrdersQueryString extends PaginationDto {
  userId?: string;
}

export interface UserSuccessfulOrderDTO {
  id: string;
  orderNumber: string;
  orderStatus?: StatusEnum;
  packageId: string;
  package: string;
  linkId: string | null;
  shortLink: string | null;
  familyId: string | null;
  family: string | null;
  payerId: string | null;
  payer: string | null;
  createDate: string;
  expireDate: string | null;
}

export interface OrderStatisticView {
  pending: number,
  process: number,
  failed: number,
  done: number,
  refund: number,
  cancel: number
}

export interface PaginationDataDto<T> {
  rows: T[];
  totalRows: number;
}

export interface OrderDto {
  id: string;
  buyerId: string;
  orderNumber: string;
  status: StatusEnum;
  discountAmount: number;
  price: number;
  createDate: string;
}

export interface OrderRowDto {
  id: string;
  userId: string;
  orderNumber: string;
  status: StatusEnum;
  discountAmount: number;
  price: number;
  createDate: string;
  package: {
    id: string;
    title: string;
    price: number;
    discount: number;
    durationDay: number;
  };
}

export interface OrderParamsDto extends PaginationDto {
  id?: string;
  buyerId?: string;
  orderNumber?: string;
  status?: StatusEnum;
  PackageId?: string;
}
