//privilage
export const FULL_ACCESS = "FULL_ACCESS";
export const DASHBOARD_MENU = "DASHBOARD_MENU";
export const PACKAGES_MENU = "PACKAGES_MENU";
export const ACCOUNTS_MENU = "ACCOUNTS_MENU";
export const PAYERS_MENU = "PAYERS_MENU";
export const FAMILIES_MENU = "FAMILIES_MENU";
export const BANK_CARDS_MENU = "BANK_CARDS_MENU";
export const PAYMENT_GATEWAY = "PAYMENT_GATEWAY";
export const USERS_MENU = "USERS_MENU";
export const ADMINS_TAB = "ADMINS_TAB";
export const USERS_TAB = "USERS_TAB";
export const FACTORS_MENU = "FACTORS_MENU";
export const ARTICLE_MENU = "ARTICLE_MENU";
export const SETTING_MENU = "SETTING_MENU";
export const SEO_SETTING_MENU = "SEO_SETTING_MENU";
export const REDIREDT_LINK_MENU = "REDIREDT_LINK_MENU";
export const ANNOUNCEMENTS_MENU = "ANNOUNCEMENTS_MENU";
export const SALES_AGENT_MENU = "SALES_AGENT_MENU";
export const DISCOUNT_CODE_MENU = "DISCOUNT_CODE_MENU";

export const SALES_AGENT = "SALES_AGENT";
export const MASTER_SALES_AGENT= "MASTER_SALES_AGENT";
export const ADMIN = "ADMIN";