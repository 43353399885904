import { Box, Card, Skeleton, Typography } from "@mui/material";

export default function BoxStatisticsView(loading: boolean, title: string, value: number | string, icon: any) {
    return (
        <Box className='flex full-width' pl={1}>
            {!loading ? (
                <Card
                    className='flex align-center'
                    sx={{
                        p: 2,
                        width: '100%',
                        justifyContent: 'space-between',
                        boxShadow: 'unset',
                        border: '1px solid #F0F2F4'
                    }}
                >
                    <div className='flex'>
                        {icon}
                        <Typography
                            sx={{
                                fontSize: 15,
                                display: 'flex',
                                alignItems: 'center',
                                pl: 1
                            }}
                        >
                            {title}
                        </Typography>
                    </div>
                    <Typography className='fa-number' variant="h4" sx={{ fontSize: 17 }}>
                        {value}
                    </Typography>
                </Card>
            ) : (
                <Skeleton width={250} height={100} />
            )}
        </Box>
    );
}