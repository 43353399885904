import React from 'react';

import { GridColDef } from '@mui/x-data-grid';

import { CustomButton } from 'src/components/shared/Button/button';
import GridData from 'src/components/shared/grid/striped/GridData';
import BooleanValue from 'src/components/utilities/BooleanValue';
import DateFormat from 'src/components/utilities/DateFormat';
import { Tooltip } from '@mui/material';
import { BlogCategoryRowDto } from 'src/services/content/BlogCategoryService';

export default class ArticleCategoryList extends React.PureComponent<
  {
    reload: () => void;
    loading: boolean;
    openEditedModal?: (row: BlogCategoryRowDto) => void;
    openDetails: (row: BlogCategoryRowDto) => void;
    delete?: (id: any) => void;
    data: BlogCategoryRowDto[];
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  renderFingerImage = (img: string) => {
    return (
      <img src={img} width={22} height={22} />
    )
  }

  render(): React.ReactNode {
    let { data, loading } = this.props;

    let colmn: GridColDef[] = [
      {
        field: 'title',
        width: 200,
        headerName: 'عنوان',
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value}><span>{params.value}</span></Tooltip>
        )
      },
      {
        field: 'orderId',
        width: 200,
        headerName: 'ترتیب',
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value}><span>{params.value}</span></Tooltip>
        )
      },
      {
        field: 'parentTitle',
        width: 200,
        headerName: 'دسته بندی',
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value}><span>{params.value}</span></Tooltip>
        )
      },
      {
        field: 'isActive',
        width: 100,
        headerName: 'وضعیت',
        sortable: false,
        renderCell: ({ value }) => BooleanValue(value)
      },
      {
        field: 'createAt',
        width: 120,
        headerName: 'تاریخ ایجاد',
        sortable: false,
        renderCell: ({ value }) => DateFormat(value)
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'center',
        width: 230,
        getActions: (params) => [
          <CustomButton
            key={params.id}
            sx={{
              fontSize: 12,
              p: '8px 12px',
            }}
            color="primary"
            variant="text"
            onClick={() => {
              this.props.openDetails(params.row)
            }}
          >
            جزئیات
          </CustomButton>,
          <CustomButton
            key={params.id}
            sx={{
              fontSize: 12,
              p: '8px 12px',
            }}
            color="warning"
            variant="text"
            onClick={() => {
              this.props.openEditedModal(params.row);
            }}
          >
            ویرایش
          </CustomButton>,
          <CustomButton
            key={params.id}
            sx={{
              fontSize: 12,
              p: '8px 12px',
            }}
            color="error"
            variant="text"
            onClick={() => {
              if (window.confirm('آیا از حذف این مقاله اطمینان دارید؟'))
                this.props.delete(params.id);
            }}
          >
            حذف
          </CustomButton>
        ]
      }
    ];

    return (
      <>
        <GridData loading={loading} columns={colmn} rows={data}></GridData>
      </>
    );
  }
}
