import React, { ChangeEvent } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TablePagination, TextField, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Visibility } from '@mui/icons-material';
import SectionView from 'src/components/shared/SectionView/SectionView';
import GridData from 'src/components/shared/grid/striped/GridData';
import IState, { Iprops } from './VisitorStatistics.models';
import { periodOptions } from 'src/models/periodOptions';
import VisitorService from 'src/services/dashboard/VisitorService';
import UserService from 'src/services/UserService';
import BoxStatisticsView from '../BoxView/BoxStatisticsViewComponent';

export default class VisitorStatistics extends React.PureComponent<Iprops, IState> {
    visitorService: VisitorService

    userService: UserService

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            period: 1,
            totalVisits: 0,
            referrer: undefined,
            rows: [],
            pageSize: 5,
            pageSkip: 0,
            totalRows: 0,
            userCount: 0,
            visitorCount: 0
        };

        this.visitorService = new VisitorService()

        this.userService = new UserService()
    }

    componentDidMount(): void {
        this.getUserCount();
        this.fetchData(0, 5, this.state.period);
    }

    fetchData = (PageSkip: number, PageSize: number, period: number) => {
        this.setState({ loading: true });
        this.visitorService.getPaged({ PageSize, PageSkip: PageSkip + 1, PeriodDate: period == 0 ? null : period, Referrer: this.state.referrer }).then((res) => {
            this.setState({
                totalRows: res.totalRows,
                rows: res.rows.map((item, index) => { return { ...item, id: index } }),
                totalVisits: res.totalVisits
            }, () => this.forceUpdate())
        }
        ).finally(() => this.setState({ loading: false }));
    };

    getUserCount = () => {
        this.userService.getUserCount().then(response => this.setState({ userCount: response }))
    }

    render(): React.ReactNode {
        let colmn: GridColDef[] = [
            {
                field: 'ip',
                width: 200,
                headerName: 'IP',
                sortable: false
            },
            {
                field: 'referrer',
                width: 400,
                headerName: 'ارجاع دهنده',
                sortable: false,
                renderCell: (params) => (
                    <Tooltip title={params.value}><span>{params.value}</span></Tooltip>
                )
            },
            {
                field: 'visits',
                width: 100,
                align: 'center',
                headerName: 'تعداد بازدید',
                sortable: false
            },
            {
                field: 'country',
                width: 300,
                headerName: 'کشور',
                sortable: false
            },
        ];


        const { rows, pageSize, pageSkip, totalRows, totalVisits, period, referrer, loading } = this.state;

        return (
            <>
                <SectionView title="لیست بازدید کنندگان">
                    <div className="flex full-width space-between align-center">
                        <Grid container display={'flex'} justifyContent={'space-between'} mb={1}>
                            <Grid item lg={2} md={2} xs={12} sm={12} display={'flex'} alignItems={'center'} mb={1}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>بازه زمانی</InputLabel>
                                    <Select
                                        value={period}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            this.fetchData(pageSkip, pageSize, Number(e.target.value))
                                            this.setState({ period: Number(e.target.value) });
                                        }}
                                        label="وضعیت"
                                    >
                                        {periodOptions.map((statusOption) => (
                                            <MenuItem key={statusOption.id} value={statusOption.id}>
                                                {statusOption.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={3} xs={12} sm={12} display={'flex'} alignItems={'center'} mb={1}>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        value={referrer}
                                        onChange={(e) => this.setState({ referrer: e.target.value }, () => this.fetchData(0, 5, 0))}
                                        margin="dense"
                                        id="referrer"
                                        label="ارجاع دهنده"
                                        type="text"
                                        fullWidth
                                        sx={{ mt: 0, mb: 0, mr: 1 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} className='size-5 font-bold flex justify-end'>
                                {BoxStatisticsView(loading, "جمع کل بازدید کننده ها ", totalRows, <Visibility sx={{ marginLeft: '4px' }} fontSize='small' color='primary' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} className='size-5 font-bold flex justify-end'>
                                {BoxStatisticsView(loading, "جمع کل بازدید ها", totalVisits, <Visibility sx={{ marginLeft: '4px' }} fontSize='small' color='primary' />)}
                            </Grid>
                        </Grid>
                    </div>
                    <GridData columns={colmn} rows={rows} loading={this.state.loading}></GridData>
                    <Box sx={{ direction: 'ltr', mb: 5 }}>
                        <TablePagination
                            component="div"
                            count={totalRows}
                            onPageChange={(event: any, newPageSkip: number) => {
                                this.setState({ pageSkip: newPageSkip });
                                this.fetchData(newPageSkip, pageSize, period);
                            }}
                            onRowsPerPageChange={(e) => {
                                this.setState({ pageSize: Number(e.target.value) });
                                this.fetchData(pageSkip, parseInt(e.target.value), period);
                            }}
                            page={pageSkip}
                            rowsPerPage={pageSize}
                            rowsPerPageOptions={[5, 10, 25, 100]}
                        />
                    </Box>
                </SectionView>
            </>
        );

    }
}
