import axios from 'axios';
import { mapToDataOnly } from './base/HttpUtility';
import { PaginationDto } from './models/models';
import { PaginationView } from './base/Pagination';
import { DiscountType } from './DiscountCodeService';

let baseUrl = '/SalesAgent'
export default class SalesAgentService {
    getPaged(dto: {
        PageSize: number,
        PageSkip: number, parentId?: string
    }): Promise<PaginationView<SalesAgentRowDTO>> {
        return mapToDataOnly<PaginationView<SalesAgentRowDTO>>(axios.get(`${baseUrl}/Get`, { params: dto }));
    }

    getSubAgents(dto: { PageSize: number, PageSkip: number, parentId?: string }): Promise<PaginationView<SalesAgentRowDTO>> {
        return mapToDataOnly<PaginationView<SalesAgentRowDTO>>(axios.get(`${baseUrl}/Agents`, { params: dto }));
    }

    getSubgroup(dto: PaginationDto, parentId: string): Promise<PaginationView<SubgroupRowDTO>> {
        return mapToDataOnly<PaginationView<SubgroupRowDTO>>(axios.get(`${baseUrl}/GetSubgroup`, { params: { ...dto, parentId } }));
    }

    getById(id: string): Promise<SalesAgentRowDTO> {
        return mapToDataOnly<SalesAgentRowDTO>(axios.get(`${baseUrl}/GetById?id=${id}`));
    }

    delete(id: string): Promise<void> {
        return mapToDataOnly<void>(axios.delete(`${baseUrl}/Delete/${id}`));
    }

    add(dto: AddParentSalesAgentDTO, isMaster: boolean): Promise<string> {
        if (isMaster)
            return mapToDataOnly<string>(axios.post(`${baseUrl}/Parent`, dto));
        else {
            let body: AddSalesAgentDTO = { isActive: dto.isActive, address: dto.address, bankCardNumber: dto.bankCardNumber, bankCardShabaNumber: dto.bankCardShabaNumber, city: dto.city, discountCode: dto.discountCode, store: dto.store, userId: dto.userId }
            return mapToDataOnly<string>(axios.post(`${baseUrl}/Insert`, body));
        }
    }

    edit(id: string, dto: AddParentSalesAgentDTO): Promise<void> {
        return mapToDataOnly<void>(axios.put(`${baseUrl}/Parent/${id}`, { ...dto }));
    }

    getInformation(): Promise<{
        joinedAt: string;
        store: string;
        id: string;
    }> {
        return mapToDataOnly<{
            joinedAt: string;
            store: string;
            id: string;
        }>(axios.get(`${baseUrl}/Information`));
    }
}

export interface AddSalesAgentDTO {
    userId: string;
    bankCardNumber: string;
    bankCardShabaNumber: string;
    store: string;
    discountCode: string;
    address: string;
    city: string;
    isActive: boolean;
}

export interface SalesAgentRowDTO {
    id: string;
    createAt: string;
    name: string;
    family: string;
    phoneNumber: string;
    email: string;
    bankCardNumber: string;
    bankCardShabaNumber: string;
    discountAmount: number;
    discountType: DiscountType;
    shareAmount: number;
    isActive: boolean;
    city?: string
    address?: string
    store?: string
    userId: string
    discountCode: string;
    commissionPercentFromSubset: number;
    productDirectCommissions?: { amount: number, productId: string }[]
}

export interface AddParentSalesAgentDTO {
    userId: string;
    phoneNumber: string;
    bankCardNumber: string;
    bankCardShabaNumber: string;
    discountType?: DiscountType;
    city?: string
    discountAmount?: number;
    discountCode?: string;
    isActive: boolean;
    address?: string
    store?: string
    productDirectCommissions?: { amount: number, productId: string }[]
    productCommissionPercentFromSubset?: { amount: number, productId: string }[]
    productPrices?: { amount: number, productId: string }[]
}

export interface SubgroupRowDTO {
}