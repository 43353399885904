import React, { } from 'react';
import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import SectionView from 'src/components/shared/SectionView/SectionView';
import UserService from 'src/services/UserService';
import IUserStatisticState, { IUserStatisticProps } from './UserStatistics.models';
import { ShowChart } from '@mui/icons-material';
import BoxStatisticsView from '../BoxView/BoxStatisticsViewComponent';

export default class UserStatistics extends React.PureComponent<IUserStatisticProps, IUserStatisticState> {
    userService: UserService

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            period: 1,
            statistic: { currentMonth: 0, currentWeek: 0, lastMonth: 0, lastWeek: 0, today: 0, total: 0, yesterday: 0 },
        };
        this.userService = new UserService()
    }

    componentDidMount(): void {
        this.fetchData();
    }

    fetchData = () => {
        this.setState({ loading: true })

        this.userService.getUsersStatistic().then((response) => {
            this.setState({ statistic: response })
        }).finally(() => this.setState({ loading: false }))
    };

    render(): React.ReactNode {
        const { statistic, loading } = this.state

        return (
            <>
                <SectionView title="کاربران ثبت نامی">
                    <div className="flex full-width space-between align-center">
                        <Grid container display={'flex'}>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "جمع کل", statistic.total, <ShowChart sx={{ marginLeft: '4px' }} fontSize='small' color='success' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "امروز", statistic.today, <ShowChart sx={{ marginLeft: '4px' }} fontSize='small' color='primary' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "دیروز", statistic.yesterday, <ShowChart sx={{ marginLeft: '4px' }} fontSize='small' color='info' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "هفته جاری", statistic.currentWeek, <ShowChart sx={{ marginLeft: '4px' }} fontSize='small' color='secondary' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "هفته گذشته", statistic.lastWeek, <ShowChart sx={{ marginLeft: '4px' }} fontSize='small' color='inherit' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "ماه جاری", statistic.currentMonth, <ShowChart sx={{ marginLeft: '4px' }} fontSize='small' color='warning' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "ماه گذشته", statistic.lastMonth, <ShowChart sx={{ marginLeft: '4px' }} fontSize='small' color='error' />)}
                            </Grid>
                        </Grid>
                    </div>
                </SectionView>
            </>
        );

    }
}
