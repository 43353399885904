import { Pagination, Stack } from '@mui/material'
import React from 'react'
import PaginationState, { PaginationProps } from "./PaginationComponent.models"
import './PaginationComponent.scss'

export default class PaginationComponent extends React.Component<PaginationProps, PaginationState>{

    constructor(props: PaginationProps) {
        super(props)

        this.state = { query: { page: 1, pageSize: 10 } }
    }

    componentDidMount = () => {
        this.changePage(1)
    }

    changePage = (page: number) => {
        let newQuery = this.state.query
        newQuery.page = page
        this.setState({ query: newQuery })
        this.props.onChangePage(this.state.query)
    }

    changeSize = (size: number) => {
        let newQuery = this.state.query
        newQuery.page = 1
        newQuery.pageSize = size
        this.setState({ query: newQuery })
        this.props.onChangePage(this.state.query)
    }
    
    render(): React.ReactNode {
        const {totalRows} = this.props
        
        return (
            <Stack spacing={2} className='pt-md flex items-end'>
                <Pagination
                    count={totalRows}
                    showFirstButton
                    showLastButton
                    siblingCount={0}
                />
            </Stack>
        )
    }
}
