import {
    Box, Card, Grid, Paper
} from "@mui/material"
import './index.scss';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from "react-router";
import MaskLight from '../../assets/images/backgrounds/mask-light.png'

interface IdentityLayoutProps {
    children?: ReactNode;
}

const IdentityLayout: FC<IdentityLayoutProps> = ({ children }) => {
    return (
        <Box
            className="identity"
            sx={{
                flex: 1,
                height: '100%'
            }}
        >
            <Paper className="paper" style={{
                height: '100vh',
                backgroundImage: `url(${MaskLight}), linear-gradient(to bottom, #fff, #ffffff)`,
                backgroundRepeat: 'round',
            }}>
                <Grid container minHeight="100%">
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                        <Box sx={{ width: 320 }}>
                            <Card sx={{ borderRadius: '16px' }}>
                                {children || <Outlet />}
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

IdentityLayout.propTypes = {
    children: PropTypes.node
};

export default IdentityLayout;