import axios from 'axios';
import { mapToDataOnly } from '../base/HttpUtility';
import { ServerResultDTO } from '../base/ServerResultDTO';

export default class AgentUserService {
  signIn(dto: { phoneNumber: string }): Promise<{ jwtToken: string, statuscode: string }> {
    return mapToDataOnly<{ jwtToken: string, statuscode: string }>(
      axios.post(`/AgentUser/SignIn`, dto)
    );
  }
  
  sendOtp(phoneNumber: string, statusMustChecked: boolean = true): Promise<ServerResultDTO<string>> {
    return mapToDataOnly<ServerResultDTO<string>>(
      axios.post(`/AgentUser/SendOtpCode`, {
        phoneNumber,
        statusMustChecked
      })
    );
  }
}