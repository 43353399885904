import React, { ChangeEvent } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import SectionView from 'src/components/shared/SectionView/SectionView';
import UserService from 'src/services/UserService';
import { Close, Done, MoreHoriz, Remove } from '@mui/icons-material';
import IOrderStatisticsState, { IOrderStatisticsProps } from './OrderStatistics.models';
import { periodOptions } from 'src/models/periodOptions';
import BoxStatisticsView from '../BoxView/BoxStatisticsViewComponent';
import OrderService from 'src/services/OrderService';

export default class OrderStatistics extends React.PureComponent<IOrderStatisticsProps, IOrderStatisticsState> {

    userService: UserService

    orderService: OrderService

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            period: 1,
            statistic: { cancel: 0, failed: 0, pending: 0, process: 0, refund: 0, done: 0 },
        };

        this.userService = new UserService()
        this.orderService = new OrderService()

    }

    componentDidMount(): void {
        this.fetchData();
    }

    fetchData = () => {
        this.setState({ loading: true })

        this.orderService.getStatistic(this.state.period == 0 ? undefined : this.state.period).then((response) => {
            this.setState({ statistic: response })
        }).finally(() => this.setState({ loading: false }))
    };

    render(): React.ReactNode {
        const { statistic, period, loading } = this.state

        return (
            <>
                <SectionView title="سفارشات">
                    <Grid container display={'flex'}>
                        <Grid item lg={2} md={12} xs={12} sm={12} display={'flex'} alignItems={'center'} mb={1}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>بازه زمانی</InputLabel>
                                <Select
                                    value={period}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ period: Number(e.target.value) }, () => this.fetchData());
                                    }}
                                    label="وضعیت"
                                >
                                    {periodOptions.map((statusOption) => (
                                        <MenuItem key={statusOption.id} value={statusOption.id}>
                                            {statusOption.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className="flex full-width space-between align-center">
                        <Grid container display={'flex'}>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "در انتظار پرداخت", statistic.pending, <MoreHoriz sx={{ marginLeft: '4px' }} fontSize='small' color='info' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "درحال انجام", statistic.process, <MoreHoriz sx={{ marginLeft: '4px' }} fontSize='small' color='warning' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "ناموفق", statistic.failed, <Remove sx={{ marginLeft: '4px' }} fontSize='small' color='error' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "تکمیل شده", statistic.done, <Done sx={{ marginLeft: '4px' }} fontSize='small' color='success' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "باز گردانی شده", statistic.refund, <Remove sx={{ marginLeft: '4px' }} fontSize='small' color='secondary' />)}
                            </Grid>
                            <Grid item lg={3} md={8} xs={12} sm={12} mt={1} className='size-5 font-bold flex'>
                                {BoxStatisticsView(loading, "لغو شده", statistic.cancel, <Close sx={{ marginLeft: '4px' }} fontSize='small' color='error' />)}
                            </Grid>
                        </Grid>
                    </div>
                </SectionView>
            </>
        );

    }
}
