import React from 'react';
import { Container } from '@mui/material';
import VisitorStatistics from './VisitorStatistics/VisitorStatistics';
import SaleStatistics from './SaleStatistics/SaleStatistics';
import UserStatistics from './UserStatistics/UserStatistics';
import OrderStatistics from './OrderStatistics/OrderStatistics';

export default class Dashboard extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (
            <>
                <Container sx={{ pt: '100px' }}>
                    <UserStatistics />
                    <div style={{ marginTop: '24px' }}>
                        <OrderStatistics />
                    </div>
                    <div style={{ marginTop: '24px' }}>
                        <VisitorStatistics />
                    </div>
                    <div style={{ marginTop: '24px' }}>
                        <SaleStatistics />
                    </div>
                </Container>
            </>
        );
    }
}
