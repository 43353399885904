import axios from 'axios';
import { mapToDataOnly } from './base/HttpUtility';
import { returnAuthenticationUrl } from 'src/constants/constants';
import { ServerResultDTO } from './base/ServerResultDTO';

export interface LoginDto {
  username: string;
  password: string;
}

export interface userInfoDto {
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  age: number;
  gender: string;
  job: string;
  countryName: string;
  countryId: number;
  city: string;
  statues: string;
  isVerified: boolean;
  isTwoFactorEnabled: boolean;
  imageUrl: string;
  roles: string[];
  privilages: string[];
}

export interface CompeletInfoDto {
  firstName: string;
  lastName: string;
  email: string;
}

export default class AuthenticationService {
  Login(Dto: LoginDto): Promise<{ accessToken: string }> {
    return mapToDataOnly<{ accessToken: string }>(
      axios.post(`${returnAuthenticationUrl()}/SignIn`, {
        ...Dto,
        email: Dto.username
      })
    );
  }

  sendOtp(phoneNumber: string, statusMustChecked: boolean = true): Promise<ServerResultDTO<string>> {
    return mapToDataOnly<ServerResultDTO<string>>(
      axios.post(`User/SendOtpCode`, {
        phoneNumber,
        statusMustChecked
      })
    );
  }

  signIn(dto: { phoneNumber: string, code: string }): Promise<{ jwtToken: string, statuscode: string }> {
    return mapToDataOnly<{ jwtToken: string, statuscode: string }>(
      axios.post(`${returnAuthenticationUrl()}/SmsProvider/SignInOtp`, dto)
    );
  }

  getUserInformation(userId: string): Promise<userInfoDto> {
    return mapToDataOnly<userInfoDto>(
      axios.get(
        `${returnAuthenticationUrl()}/Admin/Users/FullUserInfo?userId=${userId}`
      )
    );
  }

  getUserData(): Promise<userInfoDto> {
    return mapToDataOnly<userInfoDto>(
      axios.get(`${returnAuthenticationUrl()}/User/GetUserInfo`)
    );
  }

  getUserInformationByToken(): Promise<userInfoDto> {
    let CustomerAccessToken = localStorage.getItem('access-token-customer');

    return mapToDataOnly<userInfoDto>(
      axios.get(`${returnAuthenticationUrl()}/User/GetUserInfo`,
        {
          headers: {
            'authorization': `Bearer ${CustomerAccessToken}`,
          }
        }
      )
    );
  }

  completeInformationCustomer(body: CompeletInfoDto, token: string): Promise<void> {
    let CustomerAccessToken = localStorage.getItem('access-token-customer');

    return mapToDataOnly<void>(
      axios.put(`${returnAuthenticationUrl()}/User/UpdateUserInfoUseToken`, body, {
        headers: {
          'authorization': `Bearer ${CustomerAccessToken}`,
        }
      })
    );
  }
}