import axios from 'axios';
import { mapToDataOnly } from '../base/HttpUtility';

export default class SaleService {
  getPaged(dto: SaleParamsDto): Promise<PaginationDataDto> {
    return mapToDataOnly<PaginationDataDto>(
      axios.get('/Sale/Get', { params: dto })
    );
  }
}

export interface SaleParamsDto {
  PageSize: number;
  PageSkip: number;
  PeriodDate: number;
}

export interface PaginationDataDto {
  rows: SaleDto[];
  totalRows: number;
}

export interface SaleDto {
  productId: string;
  productName: string;
  failedCount: number;
  successfulCount: number;
  totalSuccessfulSale: number;
  totalUnsuccessfulSale: number;
}
