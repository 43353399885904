import { format } from 'date-fns';
import Moment from 'moment';

export default function DateFormatEn(date: any, dateFormat?: string) {
  return (
    <>
      {!date || date == null
        ? ''
        : <span title={Moment(date).utc().format('YYYY-MM-DD HH:mm')}>{format(new Date(Date.parse(date)), dateFormat || 'MMMM dd yyyy')}</span>
      }
    </>
  );
}

export function DateFormatEnSimple(date: any) {
  return (
    <>
      {!date || date == null
        ? ''
        : <span title={Moment(date).utc().format('YYYY-MM-DD')}>{Moment(date).utc().format(' YYYY-MM-DD ')}</span>
      }
    </>
  );
}
