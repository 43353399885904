export const periodOptions = [
    {
      id: 0,
      name: 'همه'
    },
    {
      id: 1,
      name: 'امروز'
    },
    {
      id: 2,
      name: 'دیروز'
    },
    {
      id: 3,
      name: 'هفته جاری'
    },
    {
      id: 4,
      name: 'هفته گذشته'
    },
    {
      id: 5,
      name: 'ماه جاری'
    },
    {
      id: 6,
      name: 'ماه گذشته'
    },
  ];