import React, { ChangeEvent } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import SectionView from 'src/components/shared/SectionView/SectionView';
import GridData from 'src/components/shared/grid/striped/GridData';
import IState, { Iprops } from './SaleStatistics.models';
import SaleService from 'src/services/dashboard/SaleService';
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TablePagination,
  Typography
} from '@mui/material';
import { periodOptions } from 'src/models/periodOptions';
import { AttachMoney } from '@mui/icons-material';
import FormatMoney from 'src/components/shared/FormatMoney/FormatMoney';
import BoxStatisticsView from '../BoxView/BoxStatisticsViewComponent';

export default class SaleStatistics extends React.PureComponent<
  Iprops,
  IState
> {
  saleService: SaleService;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      pageSize: 5,
      pageSkip: 0,
      totalRows: 0,
      period: 1,
      sumPrice: 0
    };
    this.saleService = new SaleService();
  }

  componentDidMount(): void {
    this.fetchData(0, 5, this.state.period);
  }

  fetchData = (PageSkip: number, PageSize: number, period: number) => {
    this.setState({ loading: true })

    this.saleService
      .getPaged({
        PageSize,
        PageSkip: PageSkip + 1,
        PeriodDate: period == 0 ? null : period
      })
      .then((res) =>
        this.setState({
          data: res.rows.map((item, index) => { return { ...item, id: index } }),
          sumPrice: res.rows.map(item => item.totalSuccessfulSale).reduce((prev, next) => prev + next),
          totalRows: res.totalRows
        })
      ).finally(() => this.setState({ loading: false }));
  };

  render(): React.ReactNode {
    let colmn: GridColDef[] = [
      {
        field: 'productName',
        width: 200,
        headerName: 'محصولات',
        sortable: false
      },
      {
        field: 'successfulCount',
        width: 200,
        headerName: 'تعداد موفق',
        sortable: false,
        type: 'number'
      },
      {
        field: 'failedCount',
        width: 210,
        headerName: 'تعداد ناموفق',
        sortable: false,
        type: 'number'
      },
      {
        field: 'totalSuccessfulSale',
        width: 210,
        headerName: 'جمع فروش',
        sortable: false,
        type: 'number'
      },
      {
        field: 'totalUnsuccessfulSale',
        width: 210,
        headerName: 'جمع فروش ناموفق',
        sortable: false,
        type: 'number'
      }
    ];

    const { data, pageSize, pageSkip, totalRows, period, sumPrice, loading } = this.state;

    let sumPriceView: string = sumPrice?.toLocaleString("en-US")

    return (
      <>
        <SectionView title="لیست فروش">
          <div className="flex full-width space-between align-center">
            <Grid container display={'flex'} justifyContent={'space-between'}>
              <Grid item lg={2} md={3} xs={12} sm={3} mb={1}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>بازه زمانی</InputLabel>
                  <Select
                    value={period}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.fetchData(pageSkip, pageSize, Number(e.target.value))
                      this.setState({ period: Number(e.target.value) });
                    }}
                    label="وضعیت"
                  >
                    {periodOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={3} xs={12} sm={3} mb={1} display='flex' justifyContent={'flex-end'} className='size-5 font-bold'>
                {BoxStatisticsView(loading, "جمع کل فروش", sumPriceView, <AttachMoney sx={{ marginLeft: '4px' }} fontSize='small' color='success' />)}
              </Grid>
            </Grid>
          </div>
          <GridData columns={colmn} rows={data} loading={this.state.loading}></GridData>
          <Box sx={{ direction: 'ltr' }}>
            <TablePagination
              component="div"
              count={totalRows}
              onPageChange={(event: any, newPageSkip: number) => {
                this.setState({ pageSkip: newPageSkip });
                this.fetchData(newPageSkip, pageSize, period);
              }}
              onRowsPerPageChange={(e) => {
                this.setState({ pageSize: Number(e.target.value) });
                this.fetchData(pageSkip, parseInt(e.target.value), period);
              }}
              page={pageSkip}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 25, 100]}
            />
          </Box>
        </SectionView>
      </>
    );
  }
}
