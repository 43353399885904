import axios from 'axios';
import { mapToDataOnly } from './base/HttpUtility';
import { PaginationDto } from './models/models';
import { PaginationView } from './base/Pagination';

let baseUrl = '/SalesAgentCheckout'

export default class SalesAgentCheckoutService {
    getPaged(queryString: PaginationDto, agentId?: string): Promise<PaginationView<SalesAgentCheckoutRowDto>> {
        if (agentId)
            return mapToDataOnly<PaginationView<SalesAgentCheckoutRowDto>>(axios.get(`${baseUrl}/ByAgent/${agentId}`, { params: queryString }));
        else
            return mapToDataOnly<PaginationView<SalesAgentCheckoutRowDto>>(axios.get(`${baseUrl}/Get`, { params: queryString }));
    }

    insert(body: CreateSalesAgentCheckoutDto): Promise<InsertSalesAgentCheckoutResponseDto> {
        return mapToDataOnly<InsertSalesAgentCheckoutResponseDto>(axios.post(`${baseUrl}/Insert`, body));
    }

    doneCheckout(description: string, checkoutId: string): Promise<any> {
        return mapToDataOnly<any>(axios.put(`${baseUrl}/Done`, { checkoutId, description }));
    }
}

export interface CreateSalesAgentCheckoutDto {
    salesAgentId: string;
}

export interface SalesAgentCheckoutRowDto {
    id: string;
    createAt: string;
    accountNumber: string;
    amount: number;
}

export interface InsertSalesAgentCheckoutResponseDto {
    checkoutId: string;
    bankCardNumber: string;
    bankCardShabaNumber: string;
    amount: number;
}