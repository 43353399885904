import { Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { CustomButton } from "src/components/shared/Button/button";
import { PackageDto } from "src/services/PackageService";
import AgentPakageService from "src/services/sales-panel/AgentPakageService";
import Cardview from '../../../assets/images/Cardview.png'
import APPLEARCADE from '../../../assets/images/plans-items/APPLEARCADE-plan.png'
import APPLEFITNESS from '../../../assets/images/plans-items/APPLEFITNESS-plan.png'
import APPLEMUSIC from '../../../assets/images/plans-items/APPLEMUSIC-plan.png'
import APPLENEWS from '../../../assets/images/plans-items/APPLENEWS-plan.png'
import APPLETV from '../../../assets/images/plans-items/APPLETV-plan.png'
import ICLOUD from '../../../assets/images/plans-items/ICLOUD-plan.png'
import './Packages.scss'
import FormatMoney from "src/components/shared/FormatMoney/FormatMoney";

export default class Packages extends React.PureComponent<{ redirectToProduct: (id: string) => void, nextState: () => void }, { packages: PackageDto[], loading: boolean, pendingRequest: boolean }> {

    agentPakageService: AgentPakageService;

    constructor(props: any) {
        super(props)
        this.state = { packages: [], loading: false, pendingRequest: false }
        this.agentPakageService = new AgentPakageService();
    }

    componentDidMount(): void {
        this.setState({ loading: true })
        this.agentPakageService.get().then((response) => {
            this.setState({
                packages: response
            })
        }).finally(() => this.setState({ loading: false }))
    }

    submit = () => { }

    renderImg = (key: string) => {
        switch (key) {
            case `APPLEARCADE`:
                return APPLEARCADE;
            case `APPLEFITNESS`:
                return APPLEFITNESS;
            case `APPLEMUSIC`:
                return APPLEMUSIC;
            case `APPLENEWS`:
                return APPLENEWS;
            case `APPLETV`:
                return APPLETV;
            case 'ICLOUD':
                return ICLOUD;
        }
    }

    packageView = (item: PackageDto) => {
        return <div className="card-plan" id={item.id} key={item.id}>
            <img alt="card view" className="card-img" src={Cardview} />
            <img alt={item.imageAlt} className="img-plan" src={item.imageSrc} />
            <div className="body-plan flex flex-direction-column space-between">
                <div>
                    <div className="title-plan text-center fs-5">
                        {item.title}
                    </div>
                    <div className="content-plan">
                        {
                            (item.itemsInformation && item.itemsInformation.length > 0) && item.itemsInformation.map((itemInformation, index) => {
                                return <div className="font-sm pb-sm flex align-items-center" key={index}>
                                    <img className="plan-items-img margin-end-sm" src={this.renderImg(itemInformation.image)} />
                                    <span>{itemInformation.text}</span>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="full-width">
                    <div className="font-bold font-md mt-1 mb-1 text-center fw-medium flex column justify-center align-center">
                        <div className="font-light line-through" style={{ height: '18px' }}>
                            {
                                (item?.discount > 0) && <>
                                    <span className="font-light fa-number">{FormatMoney(item.price)}</span>
                                    <span className="pr-xs size-3">تومان</span>
                                </>
                            }
                        </div>
                        <div>
                            <span className="size-6 font-heavy fa-number">{FormatMoney(item.price - item.discount)}</span>
                            <span className="pr-xs size-4">تومان</span>
                        </div>
                    </div>
                    <CustomButton color='info' onClick={() => { this.props.redirectToProduct(item.id) }} variant="contained" fullWidth>خرید</CustomButton>
                </div>
            </div>
        </div>
    }

    render(): React.ReactNode {
        let { packages, loading } = this.state;

        return <Box width={'80%'} className="flex column justify-center align-center element-center">
            <div className="size-6 font-heavy">اشتراک موردنظر را برای مشتری انتخاب کنید</div>
            {
                loading ?
                    <Grid m={5} display='flex' justifyContent={'center'} alignItems={'center'}>
                        <CircularProgress color="inherit" size={42} />
                    </Grid> :
                    <Box mt={10} className="plans">
                        {packages.length > 0 && packages.map(packageItem => {
                            return this.packageView(packageItem)
                        })}
                    </Box>
            }
        </Box>
    }
}